import React from 'react';

import cn from '@utils/cn';
import { getIconProps } from '@utils';
import { useOperation, useFlags } from '@hooks';
import Icon from '@elements/Icon';
import Flags from '@elements/Flags';

const defaultProps = {
  isEnabled: true,
  isPreview: false,
  isAdminOnly: false,
};

function NavbarDropdownOperationItem(props) {
  const {
    // name,
    label,
    operation,
    setShowDropdownMenu,
    tooltip,

    isEnabled,
    isPreview,
    isAdminOnly,
  } = { ...defaultProps, ...props };

  const {
    text,
  } = label;

  const iconProps = getIconProps(label.icon, {
    fixedWidth: true,
  });

  const flags = useFlags([], { isPreview }, props.flags);

  /**************************************************************************************************/

  const enqueueOperation = useOperation({
    key: operation.key,
    args: operation.args,
    callback: operation.callback,
    enqueueDelay: operation.enqueueDelay,
    onEnqueueSuccess: () => setShowDropdownMenu(false),
  });

  const handleClick = (_event) => {
    if (window.confirm(`Are you sure you want to execute: ${text}?`)) {
      enqueueOperation();
    }
  };

  /**************************************************************************************************/

  const px = {};

  if (isEnabled) {
    px.onClick = handleClick;
  }

  /**************************************************************************************************/

  const classNames = cn(['navbar-dropdown-item', 'navbar-item', 'is-size-6', 'tw-select-none'], {
    'navbar-item--disabled': !isEnabled,
  });

  return (
    <a
      className={classNames}
      data-tooltip-id="app-tooltip"
      data-tooltip-content={tooltip}
      {...px}
    >
      {iconProps && (
        <span className="icon">
          <Icon {...iconProps} />
        </span>
      )}

      {text}
      {isAdminOnly && (<span className="tw-font-light">*</span>)}

      <Flags
        flags={flags}
        flagClassNames="tw-opacity-75 tw-ml-2"
        displayTooltip={false}
      />
    </a>
  );
}

export default React.memo(NavbarDropdownOperationItem);
