import React from 'react';

import cn from '@utils/cn';

import NavbarDropdownDividerItem from '@components/navbar/navbar_item/NavbarDropdownDividerItem';

const defaultProps = {
  hasDivider: false,
};

const styles = {
  color: '#4A4A4A',
  fontSize: '11px',
  letterSpacing: '1px',
  lineHeight: '1.5',
  marginBottom: '5px',
  textTransform: 'uppercase',
};

function NavbarDropdownHeadingItem(props) {
  const {
    label,
  } = props;

  const hasDivider = props.hasDivider ?? defaultProps.hasDivider;

  const text = label.text;

  return (
    <>
      {hasDivider && (
        <NavbarDropdownDividerItem />
      )}
      <div className={cn(['navbar-item', 'navbar-dropdown-item', 'heading', 'tw-pb-0'])} style={styles}>
        {text}
      </div>
    </>
  );
}

export default React.memo(NavbarDropdownHeadingItem);
