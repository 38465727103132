import React from 'react';

import cn from '@utils/cn';

function NavbarDropdownDividerItem(_props) {
  return (
    <hr className={cn(['navbar-divider', 'navbar-dropdown-item'])} />
  );
}

export default React.memo(NavbarDropdownDividerItem);
