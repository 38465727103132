/***************************************************************************************************
** Router
***************************************************************************************************/

import buildUrl from '@utils/helpers/window/buildUrl';
import redirectTo from '@utils/helpers/window/redirectTo';

const __formatPath = (path) => {
  if (!path) return null;

  return path.split('/').filter((segment) => segment?.trim().length > 0).join('/');
};

const buildUrlFor = (path, params = {}) => {
  const baseUrl = window.appContext.kinndomBaseUrl;

  const urlPath = __formatPath(path);
  const url = urlPath ? `${baseUrl}/${urlPath}` : baseUrl;

  const pathQueryString = path.includes('?') && path.substring(path.indexOf('?'));
  const pathParams = pathQueryString && Object.fromEntries(new URLSearchParams(pathQueryString));

  const urlParams = { ...pathParams, ...params };

  const outputUrl = buildUrl(url, urlParams);
  return outputUrl;
};

const redirectToPath = (path) => {
  const url = buildUrlFor(path);
  redirectTo(url);
};

export default {
  buildUrlFor,
  redirectToPath,
};
