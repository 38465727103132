import React from 'react';

import cn from '@utils/cn';

const classNames = cn([
  'tw-animate-spin',
  'tw-border-[2px]',
  'tw-border-solid',
  'tw-border-[var(--bulma-border)]',
  'tw-rounded-[var(--bulma-radius-rounded)]',
  'tw-[border-right-color:transparent]',
  'tw-[border-top-color:transparent]',
  'tw-content-[""]',
  'tw-block',
  'tw-relative',
  'tw-h-[1em]',
  'tw-w-[1em]',
]);

const defaultProps = {
  color: '#999999',
  width: '0.25rem',
  size: '1rem',
  margin: '0.5rem',
};

function LoaderCircle(props) {
  const {
    color,
    width,
    size,
    margin,
  } = { ...defaultProps, ...props };

  const styles = React.useMemo(() => ({
    borderTopColor: `${color}44`,
    borderRightColor: `${color}44`,
    borderBottomColor: color,
    borderLeftColor: color,
    borderWidth: width,
    height: size,
    width: size,
    margin: margin,
  }), [color, width, size, margin]);

  return (
    <div
      className={classNames}
      style={styles}
    />
  );
}

export default React.memo(LoaderCircle);
