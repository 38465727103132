/***************************************************************************************************
** buildUrl
***************************************************************************************************/

export default function buildUrl(url, params = {}) {
  const targetLocation = new URL(url);
  const urlSegments = [targetLocation.origin];

  if (targetLocation.pathname && targetLocation.pathname !== '/') {
    urlSegments.push(targetLocation.pathname);
  }

  const paramsString = new URLSearchParams(params).toString();
  if (paramsString) {
    urlSegments.push('?', paramsString);
  }

  const outputUrl = urlSegments.join('');
  return outputUrl;
}
