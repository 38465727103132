import React from 'react';
import Icon from '@elements/Icon';

import { getIconProps } from '@utils';

const Flag = (props) => {
  const {
    label,
    icon,
    color,
    classNames,
  } = props;

  const displayTooltip = props.displayTooltip ?? true;

  const iconProps = getIconProps(icon, {
    className: classNames,
    size: 'sm',
    transform: 'shrink-1',
    fixedWidth: false,
    color: color || '#999999',
    style: {
      cursor: displayTooltip ? 'help' : 'default',
    },
  });

  if (displayTooltip) {
    return (
      <span
        className="tw-cursor-help"
        data-tooltip-id="app-tooltip"
        data-tooltip-content={label}
      >
        <Icon {...iconProps} />
      </span>
    );
  } else {
    return (
      <Icon {...iconProps} />
    );
  }
};

export default React.memo(Flag);
