/***************************************************************************************************
** interpolate
***************************************************************************************************/

// TODO: Escape character: new RegExp('(?<!x)%{.+?}', 'g'), but not if preceeded by x
// TODO: replace '％' with '\%'

import get from 'get-value';
import typeOf from '@utils/helpers/object/typeOf';

const defaultOptions = {
  templateRegexSymbol: '%',
  fallbackValue: 'null',
  interpolationMode: 'full',
};

export default function interpolate(template, data, opts = {}) {
  const options = { ...defaultOptions, ...opts };

  if (template === undefined || template === null) return {};

  const templateRegex = new RegExp(`${options.templateRegexSymbol}{.+?}`, 'g');

  switch (typeOf(template)) {
  case 'string':
    return template.replace(templateRegex, (match) => {
      const keypath = match.slice(2, -1).trim();
      const value = get(data, keypath, { separator: '.' });

      if (options.interpolationMode === 'partial' && value === undefined) {
        return match;
      }

      return value ?? options.fallbackValue;
    });

  case 'array':
    return template.map((element) => interpolate(element, data, options));

  case 'object':
    return Object.entries(template).reduce((accumulator, [key, value]) => {
      accumulator[key] = interpolate(value, data, options);

      return accumulator;
    }, {});

  default:
    return template;
  }
}
