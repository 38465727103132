/***************************************************************************************************
** typeOf
***************************************************************************************************/

export default function typeOf(obj) {
  if (obj === null || obj === undefined) return 'null';

  if (typeof obj === 'boolean') return 'boolean';
  if (typeof obj === 'number') return 'number';
  if (typeof obj === 'string') return 'string';
  if (typeof obj === 'symbol') return 'symbol';
  if (typeof obj === 'bigint') return 'bigint';
  if (typeof obj === 'function') return 'function';

  if (typeof obj === 'object') {
    if (Array.isArray(obj)) return 'array';
    if (obj instanceof Map) return 'map';
    if (obj instanceof Set) return 'set';
    if (obj instanceof Date) return 'date';
    if (obj instanceof RegExp) return 'regexp';
    if (obj instanceof Promise) return 'promise';
    if (obj instanceof File) return 'file';

    return 'object';
  }

  return typeof obj;
}
