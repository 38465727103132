import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useEffectOnce } from 'react-use';
import { Tooltip } from 'react-tooltip';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { createConsumer } from '@rails/actioncable';

import toastify from '@vendors/toastify/toastify';
import AppCommandPalette from '@vendors/AppCommandPalette/AppCommandPalette';
import { KinndomContextProvider } from '@contexts/KinndomContext';
import { SessionContextProvider } from '@contexts/SessionContext';
import { Router } from '@utils';

import PageLoader from '@elements/PageLoader';
import Navbar from '@components/navbar/Navbar';
import ErrorFallbackPage from '@pages/ErrorFallbackPage';
import Button from '@components/elements/Button';

const loadPageComponent = (componentName) => {
  return React.lazy(() => {
    return import(`./pages/${componentName}`).catch((error) => {
      console.error(error);
      return import('./pages/ErrorFallbackPage');
    });
  });
};

const defaultQueryFn = async ({ queryKey }) => {
  const path = queryKey[0];
  const params = queryKey[1] || {};

  const url = path && Router.buildUrlFor(`/api${path}`, params);

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache, no-store',
      'Authorization-Token': window.appContext.authorizationToken,
    },
  }).then((response) => response.json())
    .then((data) => data.records);
};

// const handleReceivedNotification = (response) => {
//   const { type, value, toastId } = response;

//   if (!value) return;

//   if (toastId) {
//     toastify.update({
//       type: type || 'info',
//       value: value,
//       toastId: toastId,
//     });
//   } else {
//     toastify.notify({
//       type: type || 'info',
//       value: value,
//     });
//   }
// };

function App(props) {
  const Page = loadPageComponent(props.component);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
      },
    },
  });

  /**************************************************************************************************/

  // React.useEffect(() => {
  //   const websocketUrl = RAILS_ENV === 'production' ? `wss://${window.appContext.kinndomKey}.kinn.app/cable` : `ws://localhost:3000/cable`;
  //   const consumer = createConsumer(websocketUrl);

  //   const subscription = consumer.subscriptions.create('NotificationsChannel', {
  //     received: handleReceivedNotification,
  //   });

  //   return () => {
  //     consumer.subscriptions.remove(subscription);
  //   };
  // }, []);

  /**************************************************************************************************/

  useEffectOnce(() => {
    window.consolePrintKinnMark();
  });

  useEffectOnce(() => {
    toastify.processToastQueue();
  });

  /**************************************************************************************************/

  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
      <KinndomContextProvider>
        <SessionContextProvider>
          <QueryClientProvider client={queryClient}>
            {window.appContext.isAuthenticated && (
              <Navbar />
            )}

            <React.Suspense fallback={(<PageLoader />)}>
              <Page {...props} />
            </React.Suspense>

            {window.appContext.isAuthenticated && (
              <AppCommandPalette />
            )}

            {window.appContext.isAuthenticated && window.appContext.currentUserMobileBackButtonEnabled && (
              <Button
                className="button is-light tw-visible-when-mobile tw-fixed tw-left-0 tw-top-14 tw-z-[100] tw-m-1 tw-touch-manipulation tw-select-none tw-rounded tw-p-2 tw-px-4 tw-outline-none"
                iconName="arrow-left"
                isIconOnly={true}
                onClick={() => window.history.back()}
              />
            )}

            <Tooltip
              id="app-tooltip"
              className="tooltip"
              place="bottom"
              opacity={1}
            />
            <ToastContainer
              theme="dark"
              position="top-right"
              autoClose={5000}
              newestOnTop={false}
              closeOnClick={true}
              rtl={false}
              pauseOnFocusLoss={true}
              draggable={true}
              draggablePercent={40}
              stacked={true}
              hideProgressBar={true}
            />
            <div id="modal-root" />
          </QueryClientProvider>
        </SessionContextProvider>
      </KinndomContextProvider>
    </ErrorBoundary>
  );
}

export default React.memo(App);
