/***************************************************************************************************
** getResourceConfig
***************************************************************************************************/

import resourcesConfig from './../../../../../lib/kinn/config/resources.yml';

export default function getResourceConfig(name) {
  if (!name) return undefined;

  return Object.values(resourcesConfig).find((r) => r.name === name) || {
    name: undefined,
    description: undefined,
    path: undefined,
    icon: {
      name: undefined,
    },
  };
}
