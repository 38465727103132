import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useEffectOnce } from 'react-use';
import CommandPalette from 'react-command-palette';

import { useKinndomContext } from '@contexts/KinndomContext';
import { useAsyncRequest } from '@hooks';
import { Router, redirectTo, interpolate, getIconProps } from '@utils';
import Icon from '@elements/Icon';
import Link from '@elements/Link';

import './AppCommandPalette.scss';

// https://github.com/asabaylus/react-command-palette

const SESSION_STORAGE_RECORD_COMMANDS_KEY = 'recordCommands';

const COMMAND_PALETTE_HOT_KEYS = [
  'command+k',
  'ctrl+k',
  'command+p',
  'ctrl+p',
  'command+shift+p',
  'ctrl+shift+p',
];

const DASHBOARD_NAVBAR_MENU_ITEM = {
  type: 'link',
  label: {
    text: 'Dashboard',
    icon: 'chart-tree-map',
  },
  path: '/dashboard',
};

const RELEVANT_NAVBAR_MENU_ITEM_TYPES = [
  'link',
  // 'operation',
];

const getItemsFromNavbarMenus = (menus) => {
  const navbarMenuItems = menus.filter((menu) => menu.items).flatMap((menu) => {
    return menu.items.map((item) => ({ ...item, isAdminOnly: Boolean(menu.isAdminOnly) }));
  }).filter((item) => RELEVANT_NAVBAR_MENU_ITEM_TYPES.includes(item.type));

  return [
    DASHBOARD_NAVBAR_MENU_ITEM,
    ...navbarMenuItems,
  ];
};

const getCommandFromNavbarMenuItem = (item) => {
  if (item.type === 'link' && item.url) {
    return {
      name: item.label.text,
      type: 'external-url',
      icon: item.label.icon,
      isAdminOnly: Boolean(item.isAdminOnly),
      command: () => window.open(item.url, '_blank'),
    };
  } else if (item.type === 'link' && item.path) {
    return {
      name: item.label.text,
      type: 'page',
      icon: item.label.icon,
      isAdminOnly: Boolean(item.isAdminOnly),
      command: () => redirectTo(Router.buildUrlFor(item.path)),
    };
  } else if (item.type === 'operation') {
    // TODO: Implement Operations
    return {
      name: item.label.text,
      type: 'operation',
      icon: item.label.icon,
      isAdminOnly: Boolean(item.isAdminOnly),
      operation: {
        key: item.operation.key,
        args: item.operation.args,
        callback: item.operation.callback,
        enqueueDelay: item.operation.enqueueDelay,
      },
    };
  }
};

const renderCommand = (props) => {
  const {
    name,
    type,
    icon,
    highlight,
  } = props;

  const iconProps = getIconProps(icon, {
    className: 'command-icon',
    fixedWidth: true,
  });

  return (
    <div className="item">
      <div className="tw-flex tw-items-center tw-justify-between">
        <div className="tw-flex tw-flex-row tw-items-center">
          {icon && (
            <span className="tw-ml-1 tw-mr-3">
              <Icon {...iconProps} />
            </span>
          )}
          <div className="tw-flex tw-flex-col">
            <span className="command-type">
              {type}
            </span>
            <span>
              <span
                className="command-description"
                dangerouslySetInnerHTML={{ __html: (highlight || name) }}
              />
              {type === 'external-url' && (
                <Icon
                  iconName="up-right-from-square"
                  className="tw-ml-2"
                  transform="shrink-2"
                  color="#AAAAAA"
                />
              )}
            </span>
          </div>
        </div>
        <span className="command-prompt">
          Jump to
        </span>
      </div>
    </div>
  );
};

const CommandPaletteTrigger = (_props) => (
  <Link
    className="button is-light tw-m-1 tw-p-2"
    iconName="rocket"
    isIconOnly={true}
  />
);

const COMMAND_PALETTE_OPTIONS = {
  allowTypo: true,
  key: 'name',
  keys: [
    'name',
  ],
  limit: 10,
  scoreFn: null,
  threshold: -Infinity,
};

const COMMAND_PALETTE_THEME = {
  container: 'command-palette-container',
  containerOpen: 'command-palette-containerOpen',
  content: 'command-palette-content',
  header: 'command-palette-header',
  input: 'command-palette-input',
  inputFocused: 'command-palette-inputFocused',
  inputOpen: 'command-palette-inputOpen',
  modal: 'command-palette-modal',
  overlay: 'command-palette-overlay',
  spinner: 'command-palette-spinner',
  suggestion: 'command-palette-suggestion',
  suggestionFirst: 'command-palette-suggestionFirst',
  suggestionHighlighted: 'command-palette-suggestionHighlighted',
  suggestionsContainer: 'command-palette-suggestionsContainer',
  suggestionsContainerOpen: 'command-palette-suggestionsContainerOpen',
  suggestionsList: 'command-palette-suggestionsList',
  trigger: 'command-palette-trigger',
};

function AppCommandPalette(_props) {
  const kinndom = useKinndomContext();

  // TODO: Refactor
  const interpolationData = React.useMemo(() => ({
    kinndomKey: window.appContext.kinndomKey,
    defaultWebsiteId: window.appContext.defaultWebsiteId,
    defaultWebsiteBaseUrl: window.appContext.defaultWebsiteBaseUrl,

    kinndom: kinndom,
    config: kinndom.configuration,
  }), [kinndom]);

  /***************************************************************************************************
  ** Icons from Palette.YML
  ***************************************************************************************************/

  const [paletteItems, setPaletteItems] = React.useState([]);

  React.useEffect(() => {
    import('~/lib/kinn/config/palette.yml').then((module) => {
      const items = module.default
        .filter((item) => (!item.isAdminOnly || window.appContext.isCurrentUserAdmin))
        .map((item) => {
          if (item.type === 'page') {
            return {
              ...item,
              command: () => redirectTo(Router.buildUrlFor(item.value)),
            };
          } else if (item.type === 'external-url') {
            return {
              ...item,
              command: () => window.open(item.value, '_blank'),
            };
          }
        });

      setPaletteItems(items);
    });
  }, []);

  /**************************************************************************************************/

  const navbarMenuItemCommands = React.useMemo(() => {
    const interpolatedNavbarMenus = interpolate(window.NAVBAR_MENUS, interpolationData);

    return getItemsFromNavbarMenus(interpolatedNavbarMenus)
      .filter((item) => (!item.isAdminOnly || window.appContext.isCurrentUserAdmin))
      .map((item) => getCommandFromNavbarMenuItem(item))
      .concat(paletteItems);
  }, [interpolationData, paletteItems]);

  const [commands, setCommands] = React.useState(navbarMenuItemCommands);

  const fetchRecordCommands = useAsyncRequest({
    path: '/data/record_commands',
    method: 'GET',
  });

  const loadCommands = React.useCallback(() => {
    const storedRecordCommands = sessionStorage.getItem(SESSION_STORAGE_RECORD_COMMANDS_KEY);

    if (storedRecordCommands) {
      const recordCommands = JSON.parse(storedRecordCommands).map((item) => ({
        ...item,
        command: () => redirectTo(item.path),
      }));

      setCommands([
        ...navbarMenuItemCommands,
        ...recordCommands,
      ]);
    } else {
      fetchRecordCommands().then((response) => {
        const recordCommands = response.records.map((item) => ({
          ...item,
          command: () => redirectTo(item.path),
        }));

        setCommands([
          ...navbarMenuItemCommands,
          ...recordCommands,
        ]);

        sessionStorage.setItem(SESSION_STORAGE_RECORD_COMMANDS_KEY, JSON.stringify(recordCommands));
      });
    }
  }, [fetchRecordCommands, navbarMenuItemCommands]);

  React.useEffect(() => {
    loadCommands();
  }, [loadCommands, navbarMenuItemCommands]);

  useEffectOnce(() => {
    loadCommands();
  });

  /**************************************************************************************************/

  const handlePressHotKeys = (_event, _handler) => {
    if (document.querySelector('.command-palette-containerOpen')) return;

    document.activeElement.blur();
    document.querySelector('.command-palette-trigger')?.click();
  };

  useHotkeys(['meta+k', 'ctrl+k', 'meta+shift+p', 'ctrl+shift+p'], handlePressHotKeys, {
    enabled: true,
    enableOnFormTags: ['INPUT', 'TEXTAREA', 'SELECT'],
    enableOnContentEditable: true,
    preventDefault: true,
  }, [handlePressHotKeys]);

  /**************************************************************************************************/

  if (!window.appContext.isAuthenticated) return null;

  return (
    <CommandPalette
      commands={commands}
      hotKeys={COMMAND_PALETTE_HOT_KEYS}
      defaultInputValue=""
      closeOnSelect={true}
      maxDisplayed={10}
      highlightFirstSuggestion={true}
      placeholder="Search or jump to..."
      shouldReturnFocusAfterClose={true}
      showSpinnerOnSelect={true}
      resetInputOnOpen={true}
      renderCommand={renderCommand}
      alwaysRenderCommands={true}
      options={COMMAND_PALETTE_OPTIONS}
      theme={COMMAND_PALETTE_THEME}
      trigger={(
        <CommandPaletteTrigger />
      )}
    />
  );
}

export default React.memo(AppCommandPalette);
