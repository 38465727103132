import React from 'react';
import { useToggle } from 'react-use';
import { AnimatePresence } from 'framer-motion';

import cn from '@utils/cn';
import NavbarMenuLabel from '@components/navbar/navbar_menu/NavbarMenuLabel';
import NavbarMenuDropdown from '@components/navbar/navbar_menu/NavbarMenuDropdown';

const MOUSE_LEAVE_TIME_DELAY = 750;

function NavbarMenu(props) {
  const {
    label,
    items,
    isAlignedRight,
  } = props;

  const [showDropdownMenu, toggleShowDropdownMenu] = useToggle(false);

  const handleClickMenuLabel = (event) => {
    event.target.focus();
    toggleShowDropdownMenu();
  };

  const handleMouseLeave = (_event) => {
    if (showDropdownMenu) {
      setTimeout(() => toggleShowDropdownMenu(false), MOUSE_LEAVE_TIME_DELAY);
    }
  };

  /**************************************************************************************************/

  const classNames = cn(['navbar-item', 'has-dropdown', 'tw-select-none'], {
    'is-active': showDropdownMenu,
  });

  /**************************************************************************************************/

  return (
    <div
      className={classNames}
      onMouseLeave={handleMouseLeave}
    >
      <NavbarMenuLabel
        label={label}
        onClick={handleClickMenuLabel}
      />
      <AnimatePresence mode="wait">
        {showDropdownMenu && (
          <NavbarMenuDropdown
            key="navbar-menu-dropdown"
            items={items}
            isAlignedRight={isAlignedRight}
            setShowDropdownMenu={toggleShowDropdownMenu}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

export default React.memo(NavbarMenu);
