/***************************************************************************************************
** useFlags
***************************************************************************************************/

import { useDeepCompareMemo } from 'use-deep-compare';

const BASE_FLAG_DEFINITIONS = [
  {
    key: 'isAdminOnly',
    label: 'Admin Only',
    icon: 'hat-wizard',
  },
  {
    key: 'isPreview',
    label: 'Preview',
    icon: 'person-digging',
  },
];

export default function useFlags(
  flagDefinitions = [],
  flaggability = {},
  additionalFlags = []
) {
  const flags = useDeepCompareMemo(() => {
    return BASE_FLAG_DEFINITIONS
      .concat(flagDefinitions)
      .filter((flag) => flaggability[flag.key])
      .concat(additionalFlags);
  }, [flagDefinitions, flaggability, additionalFlags]);

  return flags;
}
