/***************************************************************************************************
** booleanify
***************************************************************************************************/

export default function booleanify(object) {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (value === 'true') acc[key] = true;
    else if (value === 'false') acc[key] = false;
    else acc[key] = value;
    return acc;
  }, {});
}
