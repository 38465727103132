/***************************************************************************************************
** Constants
***************************************************************************************************/

const Constants = {};

/**************************************************************************************************/

const EMPTY_CHARACTER = '‏‏‎ ‎';
Constants.EMPTY_CHARACTER = EMPTY_CHARACTER;

Constants.BLANK_OPTION = {
  value: '--',
  label: '--',
  icon: '',
  color: '',
  sort_key: 0,
};

/***************************************************************************************************
** COUNTRIES
***************************************************************************************************/

import COUNTRIES from './../../../../app/frontend/config/data/countries.yml';

Constants.HOUSEHOLD_COUNTRY_OPTIONS = COUNTRIES.map((country) => (
  {
    value: country.alpha3,
    label: `${country.emoji}${EMPTY_CHARACTER}${EMPTY_CHARACTER}${EMPTY_CHARACTER}${country.name}`,
  }
));

/***************************************************************************************************
** US STATES
***************************************************************************************************/

import USA_STATES from './../../../../app/frontend/config/data/usa_states.yml';

Constants.US_STATES_OPTIONS = USA_STATES.map((state) => (
  {
    value: state.value,
    label: state.label,
  }
));

/***************************************************************************************************
** INDIA STATES
***************************************************************************************************/

import INDIA_STATES from './../../../../app/frontend/config/data/india_states.yml';

Constants.INDIA_STATES_OPTIONS = INDIA_STATES.map((state) => (
  {
    value: state.value,
    label: state.label,
  }
));

/**************************************************************************************************/

export default Constants;
