/***************************************************************************************************
** KinndomContext
***************************************************************************************************/

import React from 'react';

import { useFetch } from '@hooks';

export const KinndomContext = React.createContext();

export const KinndomContextProvider = (props) => {
  const [kinndom, fetchKinndom] = useFetch({
    path: `kinndoms/${window.appContext.kinndomKey}`,
  });

  React.useEffect(() => {
    if (window.appContext.kinndomKey) {
      fetchKinndom();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.appContext.kinndomKey]);

  return (
    <KinndomContext.Provider value={kinndom}>
      {props.children}
    </KinndomContext.Provider>
  );
};

export const useKinndomContext = () => {
  const context = React.useContext(KinndomContext);

  if (!context) {
    throw new Error('KinndomContext must be used within a KinndomContextProvider');
  }

  return context;
};
