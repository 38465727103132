import React from 'react';

import cn from '@utils/cn';
import Icon from '@elements/Icon';

function ErrorFallbackPage(props) {
  const {
    error,
    _resetErrorBoundary,
  } = props;

  const classNames = cn(['container', 'is-fullhd', 'tw-min-h-screen', 'tw-pb-2']);

  return (
    <div className={classNames}>
      <div className="box">
        <div className="columns">
          <div className="column is-12">
            <br />
            <div className="is-size-1 tw-text-center">
              <Icon iconName="triangle-exclamation" />
            </div>
            <div className="is-size-3 tw-text-center">
              Something went wrong!
            </div>
            <hr />
            {error?.message && (
              <pre>
                {error.message}
              </pre>
            )}
          </div>
        </div>

      </div>
    </div>
  );
}

export default React.memo(ErrorFallbackPage);
